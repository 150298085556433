.wrapper {
  max-width: 782px;
  margin: 0 auto;
  text-align: center;
}
.connectWalletBtn {
  width: 100%;
  padding: 24px 1rem;
  border-radius: 7px;
}

.counterParent {
  display: flex;
}
.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 14px;
}

.counter_count {
  width: 95px;
  height: 72px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.counterWrapper > :not(:last-child) {
  margin-right: 1rem;
}

.counterWrapper > :last-child .counter {
  margin-right: 0;
}

.counterWrapper > :last-child > p {
  display: none;
}

.itemsAvailable {
  width: 100%;
  border-radius: 7px;
  border: 4px solid #ce852f;
  padding: 1rem 1rem;
}

.btns button {
  padding: 1.5rem 1rem;
  border-radius: 10px;
}

.counterTimer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.counterTimer > :not(:last-child) {
  margin-right: 1.5rem;
}

.counterTimer > div {
  border: 4px solid #ce852f;
  padding: 1rem 4rem;
  border-radius: 7px;
}

@media (max-width: 760px) {
  .btns button {
    padding: 1rem 1rem;
  }

  .connectWalletBtn {
    padding: 1rem 1rem;
  }

  .counter_count {
    width: 75px;
    height: 52px;
  }

  .counterParent > p {
    margin-top: 0.4rem;
  }

  .itemsAvailable {
    padding: 0.8rem;
  }

  .counterTimer > div {
    padding: 1rem 2rem;
  }

  .counter {
    margin-right: 8px;
  }

  .counterWrapper > :not(:last-child) {
    margin-right: 8px;
  }
}

@media (max-width: 500px) {
  .counter {
    margin-right: 6px;
  }

  .counterWrapper > :not(:last-child) {
    margin-right: 6px;
  }

  .counter_count {
    width: 55px;
    height: 52px;
  }
}
