.wrapper {
  display: flex;
  position: relative;
  z-index: 3;
  margin-right: 55px;
  margin-bottom: 55px;
  align-items: flex-start;
  /* border: 2px solid red; */
}

.wrapper img:nth-child(2) {
  position: absolute;
  top: 55px;
  left: 55px;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
}

@media (max-width: 600px) {
  .wrapper {
    margin-right: 0px;
    margin-bottom: 0px;
    width: 100%;
  }

  .wrapper img:nth-child(2) {
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
