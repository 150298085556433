.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  z-index: 5;
  padding-top: 12vw;
}

.hero::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: url(../../../assets/images/hero-bg.png);
  background-position: center center;
  background-size: 100%;
  z-index: -1;
  background-repeat: no-repeat;
}

.hero img {
  background-color: transparent;
}

@media (max-width: 1000px) {
  .hero {
    min-height: auto;
    padding-top: 150px;
  }

  .hero::before {
    background-position: bottom center;
  }
}
@media (max-width: 500px) {
  .hero {
    padding-top: 120px;
  }
}
