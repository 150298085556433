.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem 3rem;
}

.cardIcon {
  width: 121.95px;
  height: 121.95px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerBtn {
  padding: 1.2rem 3rem;
  border-radius: 10px;
  background-color: var(--orange);
}

@media (max-width: 1000px) {
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem 3rem;
  }
}
@media (max-width: 850px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}
@media (max-width: 500px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
}
