.faqs > :not(:last-child) {
  margin-bottom: 20px;
}

.form {
  width: 100%;
  max-width: 545px;
  margin: 0 auto;
}

.form > :not(:last-child) {
  margin-bottom: 1.5rem;
}

.form input {
  width: 100%;
  padding: 1.5rem 2rem;
  border-radius: 37px;
  border: 2px solid var(--orange);
}

.form button {
  max-width: 336px;
  width: 100%;
  background-color: var(--orange);
  background: #ce852f;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 auto;
}
