.header {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 38px;
}

.wrapper footer {
  max-width: 1194px;
  width: 100%;
  margin: 0 auto;
}

.imgNftCard {
  position: relative;
}

.imgNftCard > p {
  position: absolute;
  top: 26px;
  left: 20px;
}

@media (max-width: 1000px) {
  .imgNftCard > p {
    top: 20px;
    left: 16px;
    font-size: 18px;
  }
}
@media (max-width: 760px) {
  .imgNftCard > p {
    font-size: 16px;
  }
}
